html {
  height: 100%;
  width: 100%;
  overscroll-behavior: none;
  touch-action: none;
  padding: env(safe-area-inset);
}

body {
  height: 100%;
  width: 100%;
  margin: 0px 0px 0px 0px;
  overscroll-behavior: none;
  touch-action: none;
  padding: env(safe-area-inset);
}

#root {
  height: 100%;
  width: 100%;
}