.container {
    background: #141414;
    color: white;
    padding: 0px 20px 0px 20px;
    border-radius: 20px;
    font-family: 'Oxygen', sans-serif;
    display: flex;
    flex-direction: column;
}

.container .card {
    background: #2e2e2e;
    border-radius: 20px;
    border: 1px solid white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.container #card-message,
.container #card-success,
.container #card-fail {
    height: 390px;
}

#card-success,
#card-fail {
    padding: 0px 20px 0px 20px;
}

.validation-fail {
    color: red;
    border-color: red;
}

.container .card-hide {
    display: none;
}

#loader {
    position: absolute;
    left: 40%;
    top: 100%;
    border: 16px solid #000000;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    -webkit-animation: spin 2s linear infinite;
    -moz-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    z-index: 2;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

.container .card hr {
    width: 200px;
}

.container .card p {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container .card a {
    color: white;
}

.container .card form {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-bottom: 0px;
}

.container .card form span {
    display: flex;
    padding: 5px 0px 5px 0px;
    max-width: 400px;
    width: 100%;
}

.container .card form span label {
    padding-right: 10px;
    max-width: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.container .card form span input {
    font-family: 'Oxygen', sans-serif;
    height: 35px;
    width: 100%;
    margin-right: 10px;
}

.container .card form span textarea {
    resize: none;
    font-family: 'Oxygen', sans-serif;
    height: 100px;
    width: 100%;
    margin-right: 10px;
}
 
.container .card form button {
    background-color: #121212;
    color: white;
    border: 1px solid white;
    border-radius: 5px;
    height: 45px;
    width: 100%;
    max-width: 200px;
    font-family: 'Oxygen', sans-serif;
    margin-top: 10px;
}

.container .card form button:active {
    background-color: grey;
}