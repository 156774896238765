@import "./scss/variables.scss";

.menu-block {
    left: 50%;
    position: absolute;
    transform: translate(-50%, 0px);
    user-select: none;
    z-index: 1;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5px;
    animation: fadeIn .25s;
    bottom: 10%;
    width: 60%;
}