@import "./scss/variables.scss";

@media screen and (min-width: 800px) {
  #title-block {
    font-size: 60px;
  }

  .title-block-content {
    gap: 30px;
  }
}

@media screen and (max-width: 800px) {
  #title-block {
    font-size: 50px;
  }

  .title-block-content {
    gap: 20px;
  }
}

@media screen and (max-width: 550px) {
  #title-block {
    font-size: 46px;
  }

  .title-block-content {
    gap: 10px;
  }
}

@media screen and (max-width: 500px) {
  #title-block {
    font-size: 40px;
  }
}

@media screen and (max-width: 450px) {
  #title-block {
    font-size: 34px;
  }
}

@media screen and (max-width: 400px) {
  #title-block {
    font-size: 30px;
  }
}

@media screen and (max-width: 350px) {
  #title-block {
    font-size: 26px;
  }
}

@media screen and (max-width: 300px) {
  #title-block {
    font-size: 20px;
  }
}

@media screen and (max-width: 250px) {
  #title-block {
    font-size: 14px;
  }
}

@media screen and (max-width: 200px) {
  #title-block {
    font-size: 12px;
  }
}

#title-block {
  top: 10%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -10%);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 1;
  font-weight: bolder;
  user-select: none;
  pointer-events: none;
  white-space: nowrap;
  flex-direction: column;
}

.title-block-content {
  display: flex;
}

.title-fade-in-initial {
  -webkit-animation: glow 1s ease-in-out infinite alternate, fadeIn 3s;
  -moz-animation: glow 1s ease-in-out infinite alternate, fadeIn 3s;
  animation: glow 1s ease-in-out infinite alternate, fadeIn 3s;
}


.title-fade-in {
  -webkit-animation: glow 1s ease-in-out infinite alternate, fadeIn .5s;
  -moz-animation: glow 1s ease-in-out infinite alternate, fadeIn .5s;
  animation: glow 1s ease-in-out infinite alternate, fadeIn .5s;
}

.title-fade-out {
  -webkit-animation: glow 1s ease-in-out infinite alternate, fadeOut .5s;
  -moz-animation: glow 1s ease-in-out infinite alternate, fadeOut .5s;
  animation: glow 1s ease-in-out infinite alternate, fadeOut .5s;
  opacity: 0;
}

@media screen and (min-width: 1200px) {
  .decorator {
    width: 200px;
  }
}

@media screen and (max-width: 1200px) {
  .decorator {
    width: 180px;
  }
}

@media screen and (max-width: 1000px) {
  .decorator {
    width: 130px;
  }
}

@media screen and (max-width: 900px) {
  .decorator {
    width: 100px;
  }
}

@media screen and (max-width: 700px) {
  .decorator {
    width: 80px;
  }
}

@media screen and (max-width: 650px) {
  .decorator {
    width: 50px;
  }
}

@media screen and (max-width: 600px) {
  .decorator {
    width: 40px;
  }
}

@media screen and (max-width: 550px) {
  .decorator {
    width: 35px;
  }
}

@media screen and (max-width: 450px) {
  .decorator {
    width: 30px;
  }
}

@media screen and (max-width: 400px) {
  .decorator {
    width: 25px;
  }
}

@media screen and (max-width: 300px) {
  .decorator {
    width: 20px;
  }
}

@media screen and (max-width: 250px) {
  .decorator {
    width: 20px;
  }
}

@media screen and (max-width: 200px) {
  .decorator {
    width: 5px;
  }
}

.decorator {
  border: 0px;
  height: 4px;
  background: white;
  box-shadow: 0 0 20px #fff, 0 0 30px $title-color-light, 0 0 40px $title-color-light, 0 0 50px $title-color-light, 0 0 60px $title-color-light, 0 0 70px $title-color-light, 0 0 80px $title-color-light, 1px 0px 1px #242424, 0px 1px 1px #242424, 2px 1px 1px #242424, 1px 2px 1px #242424, 3px 2px 1px #ccc, 2px 3px 1px #eee, 4px 3px 1px #ccc, 3px 4px 1px #242424, 5px 4px 1px #242424, 4px 5px 1px #242424, 6px 5px 1px #242424, 5px 6px 1px #242424, 7px 6px 1px #242424;
  ;
}

@keyframes glow {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px $title-color-dark, 0 0 40px $title-color-dark, 0 0 50px $title-color-dark, 0 0 60px $title-color-dark, 0 0 70px $title-color-dark, 1px 0px 1px #242424, 0px 1px 1px #242424, 2px 1px 1px #242424, 1px 2px 1px #242424, 3px 2px 1px #ccc, 2px 3px 1px #eee, 4px 3px 1px #ccc, 3px 4px 1px #242424, 5px 4px 1px #242424, 4px 5px 1px #242424, 6px 5px 1px #242424, 5px 6px 1px #242424, 7px 6px 1px #242424;
  }

  to {
    text-shadow: 0 0 20px #fff, 0 0 30px $title-color-light, 0 0 40px $title-color-light, 0 0 5px $title-color-light, 0 0 60px $title-color-light, 0 0 70px $title-color-light, 0 0 80px $title-color-light, 1px 0px 1px #242424, 0px 1px 1px #242424, 2px 1px 1px #242424, 1px 2px 1px #242424, 3px 2px 1px #ccc, 2px 3px 1px #eee, 4px 3px 1px #ccc, 3px 4px 1px #242424, 5px 4px 1px #242424, 4px 5px 1px #242424, 6px 5px 1px #242424, 5px 6px 1px #242424, 7px 6px 1px #242424;
    ;
  }
}