#speaker-icon {
    position: absolute;
    bottom: 50px;
    right: 30px;
    z-index: 3;
    user-select: none;
    width: 60px;
  }
  
  #speaker-icon:active {
    filter: invert(100%);
  }
  
  #volume-slider {
    position: absolute;
    bottom: 0px;
    right: 30px;
    z-index: 3;
    user-select: none;
    width: 75px;
  }
  
  @media screen and (max-width: 400px) {
    #speaker-icon {
      width: 45px;
    }
    #volume-slider {
      width: 65px;
    }
  }
  
  @media screen and (max-width: 300px) {
    #speaker-icon {
      width: 35px;
    }
    #volume-slider {
      width: 45px;
    }
  }
  
  input[type=range] {
    height: 33px;
    -webkit-appearance: none;
    margin: 10px 0;
    background: inherit;
    opacity: 65%;
  }
  input[type=range]:focus {
    outline: none;
  }
  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 1px 1px 1px #000000;
    background: #787373;
    border-radius: 5px;
    border: 1px solid #000000;
  }
  input[type=range]::-webkit-slider-thumb {
    box-shadow: 1px 1px 1px #000000;
    border: 1px solid #000000;
    height: 25px;
    width: 15px;
    border-radius: 5px;
    background: #FFFFFF;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -9.5px;
  }
  input[type=range]:focus::-webkit-slider-runnable-track {
    background: #787373;
  }
  input[type=range]::-moz-range-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 1px 1px 1px #000000;
    background: #787373;
    border-radius: 5px;
    border: 1px solid #000000;
  }
  input[type=range]::-moz-range-thumb {
    box-shadow: 1px 1px 1px #000000;
    border: 1px solid #000000;
    height: 25px;
    width: 15px;
    border-radius: 5px;
    background: #FFFFFF;
    cursor: pointer;
  }
  input[type=range]::-ms-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  input[type=range]::-ms-fill-lower {
    background: #787373;
    border: 1px solid #000000;
    border-radius: 10px;
    box-shadow: 1px 1px 1px #000000;
  }
  input[type=range]::-ms-fill-upper {
    background: #787373;
    border: 1px solid #000000;
    border-radius: 10px;
    box-shadow: 1px 1px 1px #000000;
  }
  input[type=range]::-ms-thumb {
    margin-top: 1px;
    box-shadow: 1px 1px 1px #000000;
    border: 1px solid #000000;
    height: 25px;
    width: 15px;
    border-radius: 5px;
    background: #FFFFFF;
    cursor: pointer;
  }
  input[type=range]:focus::-ms-fill-lower {
    background: #787373;
  }
  input[type=range]:focus::-ms-fill-upper {
    background: #787373;
  }
  
  .fade-in {
    -webkit-animation: fadeIn .5s;
    -moz-animation: fadeIn .5s;
    animation: fadeIn .5s;
  }