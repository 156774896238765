@import url("https://fonts.googleapis.com/css?family=Oxygen");
@import "./scss/variables.scss";

#App {
  width: 100%;
  height: 100%;
  background-color: black;
  font-family: 'Oxygen', sans-serif;
  color: white;

  #tintb_video {
    position: absolute;
    z-index: -1;
    display: none;
  }

  #gemwars-container {
    height: 0px;
    width: 0px;
  }

  .hidden {
    display: none;
  }

  .content {
    width: 100%;
    height: 100%;
    
    .loader_container {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;

      span {
        z-index: 4;
      }
    }
  }

  #css {
    pointer-events: none;
    position: fixed;
    top: 0;
    left: 0;

    canvas {
      position: fixed;
      top: 0;
      left: 0;
      pointer-events: auto;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -o-user-select: none;
      user-select: none;
    }

    .fade-in {
      -webkit-animation: fadeIn .5s;
      -moz-animation: fadeIn .5s;
      animation: fadeIn .5s;
    }
  }
}

@media screen and (min-width: 450px) {
  .btn-menu {
      font-size: 14px;
      padding: 4px 15px;
  }
}

@media screen and (max-width: 450px) {
  .btn-menu {
      font-size: 14px;
      padding: 4px 10px;
  }
}

@media screen and (max-width: 400px) {
  .btn-menu {
      padding: 4px 8px;
  }
}

@media screen and (max-width: 350px) {
  .btn-menu {
      font-size: 12px;
  }
}

@media screen and (max-width: 250px) {
  .btn-menu {
      font-size: 10px;
      padding: 5px 5px;
  }
}

.btn-menu {
  background-image: linear-gradient($menu-button-color-light, $menu-button-color-dark);
  border: 1px solid $menu-button-color-border;
  border-radius: 4px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  direction: ltr;
  font-weight: 400;
  display: block;
  font-family: 'Oxygen', sans-serif;
  letter-spacing: -.022em;
  line-height: 1.47059;
  min-width: 20px;
  overflow: visible;
  text-align: center;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
}

.btn-menu:disabled {
  cursor: default;
  opacity: .3;
}

.btn-menu:hover,
.btn-menu.hover {
  background-image: linear-gradient($menu-button-color-hover-light, $menu-button-color-hover-dark);
  border-color: $menu-button-color-hover-border;
  text-decoration: none;
}

.btn-menu:active {
  background-image: linear-gradient($menu-button-color-active-light, $menu-button-color-active-dark);
  border-color: $menu-button-color-active-border;
  outline: none;
}

.btn-menu:focus {
  box-shadow: $menu-button-box-shadow-focus;
  outline: none;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
