.gemwars-canvas-container {
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    user-select: none;
    animation: fadeIn 0.5s;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    gap: 5px;

    #gemwars-canvas {
        border: none;
        height: 600px;
        width: 350px;
    }

    .gemwars-actions-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
    }
}