$title-color-dark: #b559b4;
$title-color-light: #f372f1;
$menu-button-color-light: #66b893;
$menu-button-color-dark: #478181;
$menu-button-color-border: #406d4f;
$menu-button-color-hover-light: #79dbaf;
$menu-button-color-hover-dark: #5aa6a6;
$menu-button-color-hover-border: #59966d;
$menu-button-color-active-light: #87f3c3;
$menu-button-color-active-dark: #6cc4c4;
$menu-button-color-active-border: #68ac7e;
$menu-button-box-shadow-focus: rgba(131, 192, 253, 0.5) 0 0 0 3px;


// $title-color-dark: #0067B9;
// $title-color-light: #3D94D9;
// $menu-button-color-light: #42A1EC;
// $menu-button-color-dark: #0070C9;
// $menu-button-color-border: #0077CC;
// $menu-button-color-hover-light: #51A9EE;
// $menu-button-color-hover-dark: #147BCD;
// $menu-button-color-hover-border: #1482D0;
// $menu-button-color-active-light: #3D94D9;
// $menu-button-color-active-dark: #0067B9;
// $menu-button-color-active-border: #006DBC;
// $menu-button-box-shadow-focus: rgba(131, 192, 253, 0.5) 0 0 0 3px;